@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

* {
	box-sizing: border-box;
}

@font-face {
	font-family: "League Spartan";
	src: url("./assets/fonts/leaguespartan-bold.ttf");
}

:root {
	--c1: black;
	--footerColor: none;
	--textBoxColor: transparent;
}


html, body {
	margin: 0;
	padding: 0;
	background-color: black;
}

p, a, input, span, div {
	font-family: Nunito;
	text-decoration: none;
}

input {
	border: none;
	border-bottom: 1px solid #bbb;
}

h1, h4 {
	font-family: Work Sans,sans-serif;
	color: white;
	margin: 0 !important;
}

h3 {
	font-family: "Raleway";
	letter-spacing: 6px;
}

h2 {
	font-family: "Raleway";
    letter-spacing: 6px;
    font-size: 27px;
	text-align: center;
}

input:focus, button:focus {
	outline: 0;
}

body {
	position: relative;
}

#svg-A {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.nav-cont {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: 40px;
}

.nav-cont .nav-cont__left img {
	width: 90px;
}

.nav-cont .nav-cont__right input {
	padding: 4px 10px 4px 28px;
	font-size: 16px;
	width: 300px;
	font-weight: lighter;
}

.featured-events-cont__location {
	display: flex;
	align-items: flex-end;
	margin: 10px 0;
}

.featured-events-cont__location > img {
	height: 60px;
	width: 60px;
}

.featured-events-cont__events {
	display: flex;
	justify-content: space-between;
	color: #fff;
	position: relative;
}

.event-category-cont__events__slide-right,
.event-category-cont__events__slide-left {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	opacity: .7;
	width: 50px;
	height: 50px;
	transition: .2s;
	cursor: pointer;
	z-index: 15;
	border-radius: 999px;
	box-shadow: 0 0 10px -5px #000;
}
.event-category-cont__events__slide-right {
	right: 20px;
}
.event-category-cont__events__slide-left {
	left: 20px;
	display: none;
}
.event-category-cont__events__slide-left:hover,
.event-category-cont__events__slide-right:hover {
	opacity: 1;
}

.featured-events-cont__events__main {
	width: 620px;
	height: 720px;
	background: black;
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	animation: fadedown forwards .5s;
}

.featured-events-cont__events__BC {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	animation: fadedown forwards .5s;
}

.featured-events-cont__events__main, .ftce {
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	box-shadow: 0 0 10px -4px #000;
}

.featured-events-cont__events__main > *, .ftce > * {
	position: relative;
	z-index: 10;
	text-decoration: none;
	color: #fff;
}

.featured-events-cont__events__main__flyer {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	z-index: 0;
	transition: .2s;
}

.featured-events-cont__events__main:hover .featured-events-cont__events__main__flyer, .ftce:hover .featured-events-cont__events__main__flyer {
	transform: scale(1.05) translateY(-50%);
}
.text-wrapper {
	padding: 15px 25px;
	background-color: var(--textBoxColor);
	font-size: 12px;
	text-shadow: 0 0 30px black;
}
.text-wrapper-smaller {
	width: 100%;
	padding: 10px;
	height: 80px;
	z-index: 2;
	background-color: var(--textBoxColor);
	font-size: 12px;
}
.featured-events-cont__events__main__filter {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0.3) 28%, rgba(0,0,0,0) 40%);
	z-index: 1;
}

.featured-events-cont__events h2, .featured-events-cont__events h3 {
	margin: 0;
}

.featured-events-cont__events h3 {
	font-size: 17px;
}

.ftce {
	width: 320px;
	height: 350px;
	overflow: hidden;
}

.event-category-cont {
	width: 100%;
	animation: faderight forwards .5s;
}

.event-category-cont > a:hover h2 {
	color: #eaebed;
	
}

.event-category-cont > h2 {
	text-transform: uppercase;
	
}

.event-category-cont__events {
	width: 100%;
	overflow-x: scroll;
	overflow-y: none;
	position: relative;
	height: 380px;
	overflow-y: hidden;
}

.event-category-cont__events::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.event-category-cont__events__inner {
	display: flex;
	justify-content: flex-start;
	position: absolute;
	transition: .2s;
	top: 0;
	left: 0;
	height: 380px;
	overflow-y: hidden;
}

.ecc-e {
	width: 320px;
	height: 320px;
	background: black;
	align-self: flex-start;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	transition: 0.3s;
	cursor: pointer;
	overflow: hidden;
}
.ecc-e:hover {
	box-shadow: 0 0 11px 0px black;
}
.ecc-e .ecc-e__title {
	margin: 5px 0 0;
}
.text-wrapper h3 {
	font-size: 14px !important;
}

.ecc-e > * {
	position: relative;
	z-index: 2;
}
.ecc-e__flyer {
	position: absolute;
	height: 100%;
	object-fit: cover;
	width: 100%;
	z-index: 0;
	transition: .2s;
}
.event-date-small {
	font-size: 10px;
}
.event-date-large {
	font-size: 40px;
}
.ecc-e__filter {
	position: absolute;
	/* top: 0;
	left: 0; */
	height: 320px;
	width: 100%;
	background: linear-gradient(#00000000, transparent, #000000ab);
	background-size: 100% 200%;
	background-position: 0 0;
	transition: background-position .5s;
	z-index: 1;
}

.ecc-e__filter:hover {
	background-position: 0 100%;
}
footer {
	position: relative;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	background: var(--footerColor);
	min-height: 100px;
}

.footer__inner {
	position: relative;
	width: calc(100% - 80px);
	max-width: 1000px;
	display: flex;
	justify-content: space-between;
	padding: 50px 0;
	color: #fff;
}

.footer__inner__menus {
	display: flex;
}

.footer__inner__menus > div {
	padding-right: 70px;
}

.footer__inner__menus > div > a {
	display: block;
	margin: 20px 0;
	color: #fff;
	text-decoration: none;
}
.footer__inner__menus > div > a:hover {
	text-decoration: underline;
}

.footer__inner__newsletter__ic {
	width: 320px;
	border-radius: 999px;
	display: flex;
	overflow: hidden;
	margin: 5px 0;
}
.footer__inner__newsletter input[type="email"] {
	font-weight: lighter;
	padding: 8px 16px;
	font-size: 16px;
	border: none;
	color: #eee;
	background: #013361;
	width: 260px;
}
.footer__inner__newsletter input[type="submit"] {
	background: #00a2ff;
	width: 60px;
	border: none;
	cursor: pointer;
}
.desctop-scroll  {
	margin-top: 20px !important;
	scroll-padding: 20px !important;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	gap: 20px;	
}

.body-inner-b {
	position: relative;
	width: calc(100% - 80px);
	max-width: 1000px;
  	background-color: #00000000;
}

.cat-event-cont {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	margin: 20px 0 50px;
	animation: fadedown forwards .5s ;
	
}
.cat-event-cont .ecc-e {
	margin: 10px;
}
.back-link-category {
	color: #4a4b4d;
	text-decoration: underline;
	margin: 10px 0 0;
	animation: faderight forwards .5s;
}
.body-inner-b-category {
	min-height: 80vh;
	position: relative;
	width: calc(100% - 80px);
	max-width: 1200px;
	animation: faderight forwards .5s;
}
.page-title-category {
	color: var(--c1);
	text-align: center;
	text-transform: capitalize;
	font-size: 36px;
	animation: faderight forwards .5s;
}
.boat-wrapper{
	padding-top: 10%;
	padding-bottom: 20%;
	animation: fadeshit forwards .5s ;
}
.boatWheel{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width:20%;
	animation: wobble infinite 1.0s ;
}
.featured-picker {
	width:25%;
	/* margin: 30px; */
}
.ecc-e .featured{
	width: 15%;
}

.login {
	height: 500px;
	display: table;
	width: 100%;
	text-align: center;
}
.login-form {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}
.login-form li {
	list-style-type: none;
	
}
.login-form select {
	margin-bottom: 15px;
}
.login-form label, input, select{
	font-size: 20px;
}
.login-form input, select {
	padding-left: 20px;
	transition: .3s;
}
.login-form input:hover {
	padding-left: 20px;
	background-color: #aae0ff;
}
.login-form label, input, li, select{
	border-radius: 999px;
}
.event-category-cont__events_mobile{
	display: none ;
}
.event-category-cont__events_mobile-smaller{
	display: none ;
}

.submit-button {
	margin-top: 5px;
	width: 200px;
	border-radius: 999px;
	border: 1px solid rgb(255, 255, 255);
	color: white;
	background-color: #ff0000;
	padding: 5px;
	font-size: 20px;
	transition: .3s;
}
.submit-button:hover{
	color: rgb(255, 255, 255);
	background-color: #000000;
}
@keyframes fadeshit {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes faderight {
	0% {
		transform: translateX(-30px);
		opacity: 0;
	}
	100% {
		transform: translateX(0px);
		opacity: 1;
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
		
	}
	100% {
		transform: rotate(360deg);
		
	}
}
@keyframes fadedown {
	0% {
		transform: translateY(-30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}
@keyframes wobble {
	0% {
		transform: scale(1);
	}
	25% {
		transform: scale(0.9);
	}
	50% {
		transform: scale(1.4);
	}
	75% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}
@media only screen and (max-width: 600px) {
	h2 {
		font-size: 18px;
	}
	.desktop {
		display: none !important;
	}
	.desctop-scroll {
		justify-content: center;
	}
	.nav-cont__right {
		display: none;
	}
	.nav-cont .nav-cont__left img {
		width: 80px;
	}
	.featured-events-cont__events {
		flex-direction: column;
	}
	.featured-events-cont__events__main, .ftce {
		width: 100%;
		height: 50vh;
		margin-bottom: 10px;
	}

	.footer__inner {
		flex-direction: column;
	}
	.footer__inner__menus {
		flex-direction: column;
	}
	.footer__inner__newsletter__ic {
		width: 100%
	}
	.footer__inner__newsletter input[type="email"] {
		width: 70%;
	}
	.footer__inner__newsletter input[type="submit"] {
		width: 30%;
	}

	.body-inner-b, .footer__inner {
		width: calc(100% - 40px);
	} 
	.event-category-cont__events_mobile{
		display: block !important;
	}
	.event-category-cont__events_mobile-smaller{
		display: block !important;
	}
	.event-category-cont__events_mobile {
		width: 100%;
		overflow-x: scroll;
		overflow-y: none;
		position: relative;
		height: 380px;
		overflow-y: hidden;
	}
	.event-category-cont__events_mobile-smaller {
		width: 100%;
		overflow-x: scroll;
		overflow-y: none;
		position: relative;
		height: 250px;
		overflow-y: hidden;
	}
	.event-category-cont__events_mobile::-webkit-scrollbar {
		width: 0;  /* Remove scrollbar space */
		background: transparent;  /* Optional: just make scrollbar invisible */
	}
	
	.event-category-cont__events_mobile__inner {
		display: flex;
		justify-content: flex-start;
		position: absolute;
		transition: .2s;
		top: 0;
		left: 0;
		height: 380px;
		overflow-y: hidden;
	}
	.event-category-cont__events__slide-right,
	.event-category-cont__events__slide-left {
    display: none;
	}
	.boatWheel{
		margin-top: 20%;
		width:50%;
	}
	.login-form label {
		margin: 0% !important;
	}
	.login-form input, select {
		width: 300px !important;
	}
}